<template>
    <div class="pd20x flex1">
        <AddClasses :visible="openClasses" @close="openClasses = false" :data="currentItem" :edit="editClass" @update="getData"></AddClasses>
        <div class="contentBox h100pct ofA">
            <div class="filter" style="padding:10px 20px">
                <div>课程包数量（{{data.length}}条） </div>
                <div>
                    <label>选择分类：</label>
                    <a-select v-model="filter.cate_id" style="width: 120px" @change="onChange" class="mr10px" placeholder="请选择分类">
                        <a-select-option :value="0">
                           所有分类
                        </a-select-option>
                        <a-select-option v-for="item in options" :key="item.id" :value="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                    <label>搜索：</label>
                     <a-input-search v-model="filter.keywords" placeholder="搜索课程包" style="width: 200px" @search="()=>{pagination.page = 1;getData()}" class="mr10px"/>
                    <a-button type="primary" @click="openClasses = true; currentItem = null; editClass = false">添加课程包</a-button>
                </div>
            </div>
            <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :rowKey="'id'"
                @change="changePage"
            >
                <template slot="index" slot-scope="text, record, index">
                    {{index+1}}
                </template>
                <template slot="classBagname" slot-scope="text, record">
                    <div class="csP" @click="routerChange('课程包详情', '/exam/classDetail', {id:record.id})">
                        <div class="imgWrap"><img :src="record.img" alt=""></div>
                        <div>{{record.name}}</div>
                    </div>
                </template>
                <template slot="sids" slot-scope="text, record">
                    <span style="color:#7ebae5">{{operateSids(text, record)}}</span>
                </template>
                <template slot="cate_id" slot-scope="text">
                    <span>{{options.filter(el=>el.id == text).length?options.filter(el=>el.id == text)[0].name:'--'}}</span>
                </template>
                <template slot="exam_time" slot-scope="text">
                    {{formatDay(text*1000)}}
                </template>
                <template slot="user_num" slot-scope="text">
                    {{text == -1?'不限人数':text}}
                </template>
                <template slot="operation" slot-scope="text, record">
                    <i class="z-icon editB" @click="openClasses = true; currentItem = record; editClass = true"></i>
                    <i class="z-icon delete" @click="deleteItem(record.id)"></i>
                </template>
            </a-table>
        </div>
    </div>
</template>
<script>
const columns = [{
    title: '序号',
    scopedSlots: { customRender: 'index' },
    align:'center'
  },
  {
    title: '课程包名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'classBagname' },
    align:'center'
  }, {
    title: '简称',
    dataIndex: 'short_name',
    align:'center'
  }, {
    title: '标题',
    dataIndex: 'title',
    align:'center'
  }, {
    title: '标签',
    dataIndex: 'tag',
    align:'center'
  },{
    title: '学习人数',
    dataIndex: 'user_num',
    align:'center',
    scopedSlots: { customRender: 'user_num' },
  },{
    title: '所含科目',
    dataIndex: 'sids',
    align:'center',
    scopedSlots: { customRender: 'sids' },
  }, {
    title: '详情页',
    dataIndex: 'detail_type',
    align:'center'
  }, {
    title: '原价',
    dataIndex: 'old_price',
    align:'center'
  }, {
    title: '现价',
    dataIndex: 'now_price',
    align:'center'
  }, {
    title: '所属分类',
    dataIndex: 'cate_id',
    align:'center',
    scopedSlots: { customRender: 'cate_id' },
  },{
    title: '考试时间',
    dataIndex: 'exam_time',
    scopedSlots: { customRender: 'exam_time' },
  },{
    title: '每期有效时间',
    dataIndex: 'valid_time',
  },{
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
import {formatDay} from '@/libs/moment'
import AddClasses from '@/components/exam/addClasses'
import * as http from '@/libs/examapi'
import { routerlink } from '@/mixins/routerlink'
export default {
    name: 'classes',
    components: { AddClasses },
    mixins: [routerlink],
    data(){
        return{
            options: [],
            columns,
            filter:{
                cate_id: 0,
                keywords:''
            },
            pagination:{
                page: 1,
                size: '15',
                pageSize: 15
            },
            allSubject:[],
            data: [],
            areaval: '',
            openClasses: false,
            currentItem:'',
            editClass:false
        }
    },
    mounted(){
        this.$nextTick(()=>{
            Promise.all([this.getAllSubject(),this.getCates()]).then(res=>{
                this.getData()
            })
        })
    },
    methods:{
        formatDay,
        operateSids(text, record){
                let arr = text.split(',')
                let result = []
                arr.forEach(el=>{
                    let target = this.allSubject.filter(item=> item.id == el)
                    if(target.length){
                        result.push(target[0].name)
                    }
                })
            return result.length?result.join(','):'--'
        },
        getData(){
            let request = {...this.filter,...this.pagination}
            if(this.filter.cate_id == 0){
               request.cate_id = null
            }
            http.get_crouse_list(request).then(res=>{
                const pagination = { ...this.pagination }
                pagination.total = res.total;
                this.data = res.data
                this.pagination = pagination;
               
            }).catch(error=>{
                console.log(error)
            })
        },
        getCates(){
            return new Promise((resolve, reject)=>{
                http.get_subject_cate().then(res=>{
                    this.options = res
                    resolve()
                }).catch(error=>{
                    reject()
                })
            }) 
        },
        getAllSubject(){
            return new Promise((resolve, reject)=>{
                http.get_allSubject().then(res=>{
                    this.allSubject = res
                     resolve()
                }).catch(error=>{
                    reject()
                })
            })
        },
        handleChange(){

        },
        deleteItem(id){
            let _this = this
            this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                    http.del_crouse_bag({id}).then(res=>{
                        let {page, pagesize, total} = _this.pagination
                        if(page>1 && Number(page-1)* 15 == total - 1){
                            _this.pagination.page = Number(page-1)
                        }
                        _this.getData()
                    }).catch(error=>{
                        console.log(error)
                    })
                },
            });
        },
        changePage({current}){
            this.pagination.page = current
            this.getData()
        },
        hideModal(){

        },
        onChange(){

        },
    }
}
</script>
<style lang="less" scoped>
.imgWrap{
    width: 120px;
    height: 70px;
    overflow: hidden;
    border-radius: 4px;
    img{
        width: 100%;
    }
}
</style>